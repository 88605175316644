import { Component } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, Container, Box, Link } from "@mui/material";

import background from "src/assets/images/bg-1.png";

import "./roadmap.scss";
import Roadmap1 from 'src/components/Images/Roadmap-1';
import Roadmap2 from 'src/components/Images/Roadmap-2';
import Roadmap3 from 'src/components/Images/Roadmap-3';
import Roadmap4 from 'src/components/Images/Roadmap-4';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const styles = {
  paperContainer: {
    backgroundImage: `url(${background})`,
    backgroundSize: "stretch",
  },
};

export default class Landing extends Component {
  render() {
    return (
      <main sx={{ margin: 0, padding: 0 }}>
        <Box
          sx={{
            backgroundColor: "primary.light",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >

          <VerticalTimeline
            className="pana-road-map-root"
            lineColor={"#EC9E24"}
          >
            <VerticalTimelineElement
              className="sq-timeline"
              contentStyle={{ background: 'black', color: '#EDE7D8', border: '#4A4A4A solid 1px' }}
              contentArrowStyle={{ borderRight: '7px solid #4A4A4A' }}
              iconStyle={{ background: '#EDE7D8', color: '#fff' }}
            >
              <h3>Second Quarter - 2022</h3>
              <Roadmap1 />
              <p>
                Launching our community via Discord, Twitter, Medium, Mirror and LinkedIn. Through a combination of Medium/Mirror articles and AMAs, we provide the context and details behind the Pana protocol. We are looking to appoint moderators and community members who can help gradually deliver decentralized insurance together with us. Feedback and support is the currency of Pana DAO during this period.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="tq-timeline"
              contentStyle={{ background: 'black', color: '#EDE7D8', border: '#4A4A4A solid 1px' }}
              contentArrowStyle={{ borderRight: '7px solid #4A4A4A' }}
              iconStyle={{ background: '#EDE7D8', color: '#fff' }}
            >
              <h3>August 15th - 2022</h3>
              <Roadmap2 />
              <p>
                Token launch of the Pana token through streaming of tokens to stakers. 
                Freely distributing the Pana token to the community as fairly as possible with no-risk staking.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="tq-timeline"
              contentStyle={{ background: 'black', color: '#EDE7D8', border: '#4A4A4A solid 1px' }}
              contentArrowStyle={{ borderRight: '7px solid #4A4A4A' }}
              iconStyle={{ background: '#EDE7D8', color: '#fff' }}
            >
              <h3>September 27th - 2022</h3>
              <Roadmap2 />
              <p>
                Basic bonding functionality is released to create the master treasury. 
                The master treasury manages loss ratios in Pana DAO liquidity pools, 
                provides market making capital and helps bring decentralized insurance off-chain into the future. 
                We are seeking early adopters and core contributors in this phase who are interested in 
                creating an unbiased insurance protocol and programmable syndicate markets.​
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="tq-timeline"
              contentStyle={{ background: 'black', color: '#EDE7D8', border: '#4A4A4A solid 1px' }}
              contentArrowStyle={{ borderRight: '7px solid #4A4A4A' }}
              iconStyle={{ background: '#EDE7D8', color: '#fff' }}
            >
              <h3>October 17th - 2022</h3>
              <Roadmap2 />
              <p>
                Free token streaming is completed and bonding becomes the primary way to enter the protocol. 
                The foundation is laid to enable building the first Assurance NFTs.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="fq-timeline"
              contentStyle={{ background: 'black', color: '#EDE7D8', border: '#4A4A4A solid 1px', marginBottom: '-20em' }}
              contentArrowStyle={{ borderRight: '7px solid #4A4A4A' }}
              iconStyle={{ background: '#EDE7D8', color: '#fff' }}
            >
              <h3>Fourth Quarter - 2022</h3>
              <Roadmap3 />
              <p>
                Our first Assurance NFTs are launched, creating the earliest programmable syndicate markets. 
                The first Assurance NFTs are focused on creation of composable Pana protocol features that 
                mitigate downside risk for syndicate participants while maximizing accretive value – 
                this is the core capital model that underpins decentralized insurance in the Pana ecosystem. 
                These mutualized markets are created and owned by syndicate participants similar to 
                Lloyd’s of London syndicate markets. Pana DAO is a facilitator of these marketplaces,
                 where DAO governance plays a critical role in creation of the parameters and rewards 
                 associated with each programmable syndicate market.​
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="beyond-timeline"
              contentStyle={{ background: 'black', color: '#EDE7D8', border: '#4A4A4A solid 1px' }}
              contentArrowStyle={{ borderRight: '7px solid #4A4A4A' }}
              iconStyle={{ background: '#EDE7D8', color: '#fff' }}
            >
              <h3>And Beyond</h3>
              <Roadmap4 />
              <p>
                We ramp Assurance NFT collections which increases diversity for syndicate participants and creates a rich portfolio of parametric insurance options. We add the ability for syndicate members to take leverage against their Assurance NFT holdings in a fully composable manner. Pana DAO continues its journey towards full decentralization while retaining its ability to operate in the real-world. And most importantly, we begin the process of bringing democratized insurance off-chain to those most underserved in regions around the globe.
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </Box>
      </main>
    );
  }
}
